@media print {
	body * {
	  visibility: hidden;
	}
	.printable-area, .printable-area * {
	  visibility: visible;
	}
	.printable-area {
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	}
  }

  .hidden-parent-for-pdf {
	height: calc(100vh - 20px); /* Full page height minus 2 cm margin */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  }

  .left-column,
  .right-column {
	height: 100%;
	width: calc(50% - 10px); /* Half of the remaining width minus 1 cm margin between columns */
	box-sizing: border-box;
  }

