@media print {
    @page {
        size: landscape;
        margin: 2cm;
    }

    body * {
        visibility: hidden;
    }

    .print-table, .print-table * {
        visibility: visible;
    }

    .print-table {
        position: absolute;
        left: 0;
        top: 0;
        width: 95%;
        page-break-inside: avoid;
        page-break-after: avoid;
    }

    .print-table tr, .print-table th, .print-table td {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    .print-table th, .print-table td {
        font-size: 12px;
        padding: 4px;
    }
}
